<template>
  <BasePageSection
    class="narrative"
    v-bind="{ ...$attrs, ...pageContentAttrs }"
    :text-cols="textCols"
  >
    <VLayout justify-center
      ><VFlex xs12 md11 lg11 :py-5="['xl'].includes($mq)">
        <VLayout
          row
          wrap
          fill-height
          :align-center="['xs', 'sm', 'md'].includes($mq)"
          :class="[
            'section--layout',
            { 'no-img': !hasImg },
            { 'px-4': !['md', 'xs', 'sm'].includes($mq) && hasImg },
            { 'px-0': ['md', 'xs', 'sm'].includes($mq) && hasImg },
          ]"
        >
          <VFlex
            v-bind="textSectionAttrs"
            :[textLayoutOrder]="true"
            :class="[
              { 'pt-5': !['xs', 'sm'].includes($mq) && hasImg },
              { 'py-2': ['xs', 'sm'].includes($mq) },
              { 'pr-4': !['xs', 'sm'].includes($mq) && hasImg },
            ]"
          >
            <slot name="title">
              <BasePageContent
                :content-key="`ns_${sectionId}_title`"
                :tag-class="[
                  titleClass,
                  'section--inner-title pb-3 mb-3 d-inline-block',
                ]"
                tag="h3"
              />
            </slot>
            <div
              :class="['text-content', { 'pb-2': ['xs', 'sm'].includes($mq) }]"
            >
              <slot />
            </div>
          </VFlex>
          <VFlex
            v-if="hasImg"
            :class="[
              'text-xs-center d-flex align-center',
              { 'pl-3': ['lg'].includes($mq) },
            ]"
            v-bind="imgSectionAttrs"
            :[imgLayoutOrder]="true"
          >
            <div
              :class="[
                'img-wrap',
                { 'drop-shadow': !contentBgColor },
                ...imgClasses,
              ]"
            >
              <img :src="img" :alt="imgCaption || `${getSiteTitle} Image`" />
              <BasePageContent
                v-if="imgCaption.length"
                :content-key="`ns_${sectionId}_img_caption`"
                tag-class="subheading text-xs-center img-caption"
                tag="div"
              />
            </div>
          </VFlex> </VLayout></VFlex
    ></VLayout>
  </BasePageSection>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PageSectionNarrative',
  props: {
    sectionId: {
      type: [String, Number],
      default: null,
    },
    textCols: {
      type: Number,
      default: 5,
    },
    titleClass: {
      type: String,
      default: 'header-4',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('site', ['getSiteTitle']),
    ...mapGetters('pages', ['getThePage', 'getPageContent']),
    bgImage() {
      return this.getContent('bg_image')
    }, // bgImage
    bgColor() {
      return this.getContent('bg_color')
    }, // bgColor
    contentBgColor() {
      return this.getContent('content_bg_color')
    }, // contentBgColor
    pageContentAttrs() {
      return {
        contentBgColor: this.contentBgColor,
        bgColor: this.bgColor,
        bgImage: this.bgImage,
      }
    }, // pageContentAttrs
    img() {
      return this.getContent('img')
    }, // img
    imgCaption() {
      return this.getContent('img_caption') || ''
    }, // imgCaption
    imgPos() {
      return this.getContent('img_pos') || 'right'
    }, // imgPos
    imgClasses() {
      let classObj = {}
      if (!['sm', 'xs'].includes(this.$mq)) {
        let marginAttr = this.imgPos === 'right' ? 'ml-4' : 'mr-4'
        classObj[marginAttr] = true
      }

      return classObj
    }, // imgClasses
    hasImg() {
      return !!this.img
    }, // hasImg
    textSectionAttrs() {
      let cols = this.textCols
      if (this.hasImg) {
        return {
          [`lg${cols}`]: true,
          [`md${cols}`]: true,
          [`sm${cols}`]: true,
          // sm12: true,
          xs12: true,
          ...(['md'].includes(this.$mq) && { 'pl-4': true }),
        }
      } else {
        return {
          // [`lg${cols}`]: true,
          // [`md${cols + 1}`]: true,
          // 'offset-md2': true,
          [`sm10`]: true,
          'offset-sm1': true,
          xs12: true,
          'mx-auto': true,
        }
      }
    }, // textSectionAttrs
    imgSectionAttrs() {
      let cols = 12 - this.textCols
      return {
        [`lg${cols}`]: true,
        ...(this.imgPos === 'left' && { [`lg${cols}`]: true }),
        [`offset-lg-1`]: true,
        [`md${cols}`]: true,
        [`offset-md-0`]: true,
        [`sm${cols}`]: true,
        // sm12: true,
        xs12: true,
      }
    }, // imgSectionAttrs
    textLayoutOrder() {
      return this.imgPos === 'right' ? 'order-xs1' : 'order-xs2'
    }, // textLayoutOrder
    imgLayoutOrder() {
      return this.imgPos === 'right' ? 'order-xs2' : 'order-xs1'
    }, // imgLayoutOrder
    allPropsAndAttrs() {
      return { ...this.$props, ...this.$attrs }
    }, // allPropsAndAttrs
  },
  methods: {
    getContent(key) {
      return this.getPageContent(`ns_${this.sectionId}_${key}`)
    }, // getContent
  },
}
</script>

<style lang="scss">
@import '@design';
@import '@styleMixins';

.section--layout {
  padding-top: $size-grid-padding * 1;
  padding-bottom: $size-grid-padding * 1;
  &.no-img {
    padding-top: 0;
    padding-bottom: 0;

    @media (min-width: 1200px) {
      padding-top: $size-grid-padding * 3;
      padding-bottom: $size-grid-padding * 3;
    }
  }
  img {
    width: 100%;
  }
}

.img-wrap {
  position: relative;
  img {
    position: relative;
    z-index: 2;
    height: auto;
  }

  @media (min-width: 960px) {
    &.drop-shadow::after {
      position: absolute;
      bottom: -20px;
      left: -20px;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      background-color: rgba(black, 0.15);
      border: 5px solid white;
    }
  }
  .img-caption {
    @include font-size(28px);

    margin-top: 20px;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 0.04em;
  }
}
</style>
