var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePageSection',_vm._b({staticClass:"narrative",attrs:{"text-cols":_vm.textCols}},'BasePageSection',Object.assign({}, _vm.$attrs, _vm.pageContentAttrs),false),[_c('VLayout',{attrs:{"justify-center":""}},[_c('VFlex',{attrs:{"xs12":"","md11":"","lg11":"","py-5":['xl'].includes(_vm.$mq)}},[_c('VLayout',{class:[
          'section--layout',
          { 'no-img': !_vm.hasImg },
          { 'px-4': !['md', 'xs', 'sm'].includes(_vm.$mq) && _vm.hasImg },
          { 'px-0': ['md', 'xs', 'sm'].includes(_vm.$mq) && _vm.hasImg } ],attrs:{"row":"","wrap":"","fill-height":"","align-center":['xs', 'sm', 'md'].includes(_vm.$mq)}},[_c('VFlex',_vm._b(_vm._b({class:[
            { 'pt-5': !['xs', 'sm'].includes(_vm.$mq) && _vm.hasImg },
            { 'py-2': ['xs', 'sm'].includes(_vm.$mq) },
            { 'pr-4': !['xs', 'sm'].includes(_vm.$mq) && _vm.hasImg } ]},"VFlex",_vm._d({},[_vm.textLayoutOrder,true])),'VFlex',_vm.textSectionAttrs,false),[_vm._t("title",[_c('BasePageContent',{attrs:{"content-key":("ns_" + _vm.sectionId + "_title"),"tag-class":[
                _vm.titleClass,
                'section--inner-title pb-3 mb-3 d-inline-block' ],"tag":"h3"}})]),_c('div',{class:['text-content', { 'pb-2': ['xs', 'sm'].includes(_vm.$mq) }]},[_vm._t("default")],2)],2),(_vm.hasImg)?_c('VFlex',_vm._b(_vm._b({class:[
            'text-xs-center d-flex align-center',
            { 'pl-3': ['lg'].includes(_vm.$mq) } ]},"VFlex",_vm._d({},[_vm.imgLayoutOrder,true])),'VFlex',_vm.imgSectionAttrs,false),[_c('div',{class:[
              'img-wrap',
              { 'drop-shadow': !_vm.contentBgColor } ].concat( _vm.imgClasses )},[_c('img',{attrs:{"src":_vm.img,"alt":_vm.imgCaption || (_vm.getSiteTitle + " Image")}}),(_vm.imgCaption.length)?_c('BasePageContent',{attrs:{"content-key":("ns_" + _vm.sectionId + "_img_caption"),"tag-class":"subheading text-xs-center img-caption","tag":"div"}}):_vm._e()],1)]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }